<template>
  <div v-if="!!getLocalData" class="tooltip__cloud" ref="tooltipCloudRef">
    <div class="tooltip__cloud-inner" ref="tooltipCloudInnerRef">
      {{ getLocalData }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "TooltipCloud",
  data() {
    return {
      localInfo: {
        data: "",
        sourceElement: null,
      },
      TOP_OFFSET_BORDER: 50,
    };
  },
  methods: {
    ...mapMutations({
      setTooltipInfo: "expensesStore/setTooltipInfo",
    }),
    changePosition() {
      if (!this.$refs.tooltipCloudRef) {
        return;
      }
      const sourceElement = this.localInfo.sourceElement;
      if (sourceElement) {
        const tooltipCloudRef = this.$refs.tooltipCloudRef;
        const sourceElementCoords = sourceElement.getBoundingClientRect();
        const sourceElementTopOffset =
          sourceElementCoords.top -
          tooltipCloudRef.offsetHeight -
          this.TOP_OFFSET_BORDER;
        const newLeftValue =
          sourceElementCoords.left + sourceElementCoords.width + 5;

        let newTopValue = sourceElementCoords.top - sourceElementCoords.height;

        if (sourceElementTopOffset < 0) {
          newTopValue = sourceElementCoords.top + sourceElementCoords.height;
        }
        tooltipCloudRef.style.top = newTopValue + "px";
        tooltipCloudRef.style.left = newLeftValue + "px";
        tooltipCloudRef.style.zIndex = "20";
        tooltipCloudRef.style.opacity = "1";
      }
    },
  },
  computed: {
    ...mapGetters({
      getTooltipInfo: "expensesStore/getTooltipInfo",
    }),
    getLocalData() {
      return this.localInfo.data;
    },
  },
  watch: {
    getTooltipInfo: {
      handler(newTooltipInfo) {
        this.localInfo = newTooltipInfo;
      },
      deep: true,
    },
    localInfo: {
      handler() {
        if (!this.localInfo.data) {
          const tooltipCloudRef = this.$refs.tooltipCloudRef;
          tooltipCloudRef.style.zIndex = "-1";
          tooltipCloudRef.style.opacity = "0";
        } else {
          setTimeout(() => {
            this.changePosition();
          }, 10);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.tooltip__cloud {
  z-index: -1;
  display: block;
  opacity: 0;
  max-width: 450px;
  padding: 4px;
  border-radius: 8px;
  position: fixed;
  left: 50px;
  background-color: #ffffff;
  transition: opacity 0.4s 0.3s ease-in-out;
  box-shadow: 2px 1px 5px #ccc;
}

.tooltip__cloud-inner {
}
</style>
