<template>
  <div class="interval" v-click-outside="closeModal">
    <button
      class="interval__button interval__button--preview"
      :class="{ 'interval__button--disabled': !hasPrevInterval }"
      @click="preview"
    >
      <CalendarArrowIcon
        class="interval__button-icon interval__button-icon--preview"
      />
    </button>
    <div class="interval__select">
      <div class="interval__select-display" @click="toggleModal">
        <CalendarIcon />
        {{ display }}
      </div>
      <ul v-show="isOpen" class="interval__select-modal">
        <li
          v-for="mode of availableModes"
          :key="mode.id"
          class="interval__select-modal-item"
        >
          <span
            class="interval__select-modal-item-part interval__select-modal-item-part--mode"
            :class="{
              'interval__select-modal-item-part--selected':
                mode.id === currentMode,
            }"
            @click="selectMode(mode.id)"
            >{{ mode.label }}</span
          >
          <span
            v-if="mode.id === defaultMode"
            class="interval__select-modal-item-part interval__select-modal-item-part--info interval__select-modal-item-part--default"
          >
            Default
          </span>
          <span
            v-else
            class="interval__select-modal-item-part interval__select-modal-item-part--info interval__select-modal-item-part--set-default"
            @click="setDefaultMode(mode.id)"
            >Set default</span
          >
        </li>
      </ul>
    </div>
    <button
      class="interval__button interval__button--next"
      :class="{ 'interval__button--disabled': !hasNextInterval }"
      @click="next"
    >
      <CalendarArrowIcon
        class="interval__button-icon interval__button-icon--next"
      />
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { daysAYear } from "@/assets/utils";

import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";
import updateLocale from "dayjs/plugin/updateLocale";
import CalendarIcon from "@/assets/img/calendar-icon.svg?inline";
import CalendarArrowIcon from "@/assets/img/calendar-arrow-icon.svg?inline";

dayjs.extend(isoWeek).extend(weekOfYear).extend(updateLocale);

dayjs.updateLocale("en", {
  monthsShort: [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Мая",
    "Июня",
    "Июля",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
  ],
});

export default {
  name: "IntervalSelectCommon",
  components: {
    CalendarIcon,
    CalendarArrowIcon,
  },
  props: {
    lastPlanningDate: {
      type: String,
    },
    firstPlanningDate: {
      type: String,
    },
    availableModes: {
      type: Array,
      require: true,
      validator: (data) => {
        if (!data.length) {
          return false;
        }
        const validIDs = ["week", "month", "quarter", "year"];
        let isValid = true;
        data.forEach((item) => {
          if (!item.label || !item.id || !validIDs.includes(item.id)) {
            isValid = false;
          }
        });
        return isValid;
      },
    },
    initDate: {
      validator: (value) => value === null || typeof value === "object",
      default: null,
    },
    storageKey: {
      type: String,
      require: true,
    },
    getIntervalAtInit: {
      type: Boolean,
      default: false,
    },
    datesMap: {
      type: Array,
    },
    delay: {
      type: Number,
      default: 1500,
    },
  },
  data() {
    return {
      lastTicket: null,
      startingPoint: null,
      amount: 0,
      since: null,
      until: null,
      currentMode: "",
      defaultMode: "",
      isOpen: false,
      quarters: {
        index: null,
        map: [
          // номера месяцев по кварталам
          [1, 3],
          [4, 6],
          [7, 9],
          [10, 12],
        ],
        setCurrent(monthNumber) {
          this.index = this.map.findIndex(
            ([leftEdge, rightEdge]) =>
              leftEdge <= monthNumber && monthNumber <= rightEdge
          );
        },
        get getInterval() {
          return this.map[this.index];
        },
      },
    };
  },
  created() {
    this.initCurrentMode();
  },
  watch: {
    startingPoint: {
      handler(date) {
        if (!date) {
          return;
        }
        this.setDates();
        this.returnInterval();
      },
      immediate: true,
    },
    initDate: {
      handler(value) {
        if (this.startingPoint === null) {
          this.startingPoint = value;
        }
      },
      immediate: true,
    },
    getMyDate: {
      handler(value) {
        if (this.startingPoint === null) {
          this.startingPoint = value;
        }
      },
      immediate: true,
    },
    datesMap: {
      handler(map) {
        const dates = [...map].reverse();
        if (!dates.length) {
          return;
        }
        const intervalType =
          this.currentMode === "quarter" ? "month" : this.currentMode;

        const initDate = dates.find(
          ([date]) => dayjs(date).endOf(intervalType) >= this.startingPoint
        );

        this.startingPoint = dayjs(initDate[0]).startOf(intervalType);
      },
    },
  },
  computed: {
    display() {
      if (!this.getMyDate || !this.since) {
        return "";
      }
      const myWeek = this.getMyDate.week();
      const myMonth = this.getMyDate.month();
      const myYear = this.getMyDate.year();
      const currentWeek = this.since.week();
      const sinceMonth = this.since.month();
      const untilMonth = this.until.month();
      const currentYear = this.since.year();
      const selectedWeek =
        this.since.day() === 0 ? currentWeek - 1 : currentWeek;
      if (
        this.currentMode === "week" &&
        myWeek === selectedWeek &&
        myYear === currentYear
      ) {
        return "эта неделя";
      } else if (
        this.currentMode === "month" &&
        myMonth === sinceMonth &&
        myYear === currentYear
      ) {
        return "этот месяц";
      } else if (
        this.currentMode === "quarter" &&
        myMonth >= sinceMonth &&
        myMonth <= untilMonth &&
        myYear === currentYear
      ) {
        return "этот квартал";
      } else if (this.currentMode === "year" && myYear === currentYear) {
        return "этот год";
      }
      return `${this.since.format(
        myYear === this.since.year() ? "DD MMM" : "DD MMM YYYY"
      )} - ${this.until.format(
        myYear === this.until.year() ? "DD MMM" : "DD MMM YYYY"
      )}`;
    },
    hasNextInterval() {
      if (this.currentMode === "year") {
        return (
          this.until && this.until.year() < dayjs(this.lastPlanningDate).year()
        );
      }
      return this.until < dayjs(this.lastPlanningDate).endOf("month");
    },
    hasPrevInterval() {
      if (this.currentMode === "year") {
        return (
          this.since && this.since.year() > dayjs(this.firstPlanningDate).year()
        );
      }
      return this.since > dayjs(this.firstPlanningDate).startOf("month");
    },
    ...mapGetters({
      getMyDate: "userStore/getMyDate",
    }),
  },
  methods: {
    setDates() {
      if (!this.startingPoint) {
        return;
      }
      const myDate = this.startingPoint;
      if (this.currentMode === "week") {
        this.since = myDate.isoWeekday(1);
        this.until = this.since.isoWeekday(7);
        this.amount = 7;
      } else if (this.currentMode === "month") {
        this.since = myDate.set("date", 1);
        this.until = this.since.endOf("month");
        this.amount = this.since.daysInMonth();
      } else if (this.currentMode === "quarter") {
        this.quarters.setCurrent(myDate.month() + 1);
        const [sinceMonthNumber, untilMonthNumber] = this.quarters.getInterval;
        const year = myDate.year();
        this.since = dayjs(`${year}-${sinceMonthNumber}`).startOf("month");
        this.until = dayjs(`${year}-${untilMonthNumber}`).endOf("month");
        this.amount = this.until.diff(this.since, "day") + 1;
      } else if (this.currentMode === "year") {
        this.since = dayjs(`${myDate.year()}-01-01`);
        this.until = dayjs(`${this.since.year()}-12-31`);
        this.amount = daysAYear(this.since.year());
      }
      if (this.getIntervalAtInit) {
        this.returnInterval();
      }
    },
    closeModal() {
      this.isOpen = false;
    },
    setDefaultMode(modeID) {
      let storageValue = null;
      if (modeID) {
        storageValue = modeID;
      } else {
        storageValue = this.getModeByStorage();
      }
      window.localStorage.setItem(this.storageKey, storageValue);
      this.defaultMode = storageValue;
    },
    initCurrentMode() {
      this.setDefaultMode();
      this.currentMode = this.defaultMode;
      return this.currentMode;
    },
    async selectMode(mode) {
      this.$emit("changeMode", mode, this.currentMode);
      this.isOpen = false;
      this.currentMode = mode;
      if (this.since.year() !== this.getMyDate.year()) {
        this.startingPoint = this.getMyDate;
      }
      this.setDates();
      await this.returnInterval();
    },
    getModeByStorage() {
      const storageValue = window.localStorage.getItem(this.storageKey);
      if (this.availableModes.find((mode) => mode.id === storageValue)) {
        return storageValue;
      }
      return this.availableModes[0].id;
    },
    preview() {
      const currentDate = this.since;
      const step = this.getStep(currentDate.format("YYYY-M"), "prev");
      if (this.currentMode === "week") {
        this.since = currentDate.subtract(1 * step, "week");
        this.until = this.since.isoWeekday(7);
        this.amount = 7;
      } else if (this.currentMode === "month") {
        this.since = currentDate.subtract(1 * step, "month");
        this.until = this.since.endOf("month");
        this.amount = this.since.daysInMonth();
      } else if (this.currentMode === "quarter") {
        this.since = currentDate.subtract(3 * step, "month");
        this.until = this.since.add(2, "month").endOf("month");
        this.amount = this.until.diff(this.since, "day") + 1;
      } else if (this.currentMode === "year") {
        this.since = currentDate.subtract(1 * step, "year");
        this.until = this.since.endOf("year");
        this.amount = daysAYear(this.since.year());
      } else {
        console.warn("API для данного режима не реализовано.");
      }
      this.returnInterval();
    },
    next() {
      const currentDate = this.since;
      const step = this.getStep(currentDate.format("YYYY-M"), "next");

      if (this.currentMode === "week") {
        this.since = currentDate.add(1 * step, "week");
        this.until = this.since.isoWeekday(7);
        this.amount = 7;
      } else if (this.currentMode === "month") {
        this.since = currentDate.add(1 * step, "month");
        this.until = this.since.endOf("month");
        this.amount = this.since.daysInMonth();
      } else if (this.currentMode === "quarter") {
        this.since = currentDate.add(3 * step, "month");
        this.until = this.since.add(2, "month").endOf("month");
        this.amount = this.until.diff(this.since, "day") + 1;
      } else if (this.currentMode === "year") {
        this.since = currentDate.add(1 * step, "year");
        this.until = this.since.endOf("year");
        this.amount = daysAYear(this.since.year());
      } else {
        console.warn(
          `API для данного режима не реализовано. Установленный режим: ${this.currentMode}`
        );
      }
      this.returnInterval();
    },
    toggleModal() {
      if (this.availableModes.length < 2) {
        this.isOpen = false;
        return;
      }
      this.isOpen = !this.isOpen;
    },
    async returnInterval(withDelay = true) {
      const format = "YYYY-MM-DD";
      let interval = null;

      if (
        this.currentMode === "week" &&
        this.until.get("date") < this.since.get("date")
      ) {
        interval = [
          {
            amount: this.amount,
            since: this.since.format(format),
            until: this.since.endOf("month").format(format),
          },
          {
            amount: this.amount,
            since: this.until.set("date", 1).format(format),
            until: this.until.format(format),
          },
        ];
      } else {
        interval = [
          {
            amount: this.amount,
            since: this.since.format(format),
            until: this.until.format(format),
          },
        ];
      }
      await this.debounceGetInterval(interval, withDelay ? this.delay : 0);
    },
    debounceGetInterval(interval, ms) {
      return new Promise((resolve) => {
        let currentTicket = null;

        currentTicket = setTimeout(() => {
          if (currentTicket === this.lastTicket) {
            this.$emit("getInterval", interval);
            this.lastTicket = null;
          }
          resolve();
        }, ms);
        this.lastTicket = currentTicket;
      });
    },
    getStep(date, route) {
      return (
        (this.datesMap?.find(([dateFromList]) => dateFromList === date) ||
          [])[1]?.[route] || 1
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.interval {
  height: 36px;
  display: flex;
  width: 360px;
  user-select: none;
  margin-bottom: 30px;
}

.interval__button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #95899b;
  width: 36px;
  height: 100%;
  cursor: pointer;
}

.interval__button--disabled {
  pointer-events: none;
  background-color: #95899b;
}

.interval__button--preview {
  border-radius: 10px 0 0 10px;
}

.interval__button--next {
  border-radius: 0 10px 10px 0;
}

.interval__button-icon {
  color: #95899b;
  flex-shrink: 0;

  .interval__button--disabled & {
    color: #95899b;
  }
}

.interval__button-icon--next {
  transform: rotate(180deg);
}

.interval__select {
  flex-grow: 1;
  border-width: 1px 0;
  border-color: #95899b;
  border-style: solid;
  position: relative;
}

.interval__select-display {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 18px 0 8px;
  cursor: pointer;
}

.interval__select-modal {
  width: 100%;
  position: absolute;
  list-style: none;
  top: 40px;
  box-shadow: 0 2px 6px 0 #d5d0d7;
  z-index: 30;
  border-radius: 8px;
  margin-left: 0;
  padding-left: 0;
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: auto;
  background-color: #f5f5f5;
}

.interval__select-modal-item {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 5px 15px;
}

.interval__select-modal-item-part {
  align-self: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 3px 8px;
}

.interval__select-modal-item-part--mode {
  font-weight: 600;
}

.interval__select-modal-item-part--selected {
  background-color: #fce8fa;
  color: #ca62c0;
}

.interval__select-modal-item-part--info {
  font-size: 0.9rem;
}

.interval__select-modal-item-part--default {
  background-color: #d5d0d7;
  cursor: default;
}

.interval__select-modal-item-part--set-default {
  display: none;

  .interval__select-modal-item:hover & {
    display: block;
  }
}
</style>
