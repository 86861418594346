<template>
  <div :class="{ tooltip: true, ...classNames }" ref="tooltipWrapperRef">
    <div class="tooltip__visible-data" ref="tooltipVisibleDataRef">
      <div
          class="tooltip__visible-inner"
          @mouseover="(withDots || withIcon) && onTooltipMouseOver()"
          @mouseleave="(withDots || withIcon) && onTooltipMouseLeave()"
          @dblclick="
          () => {
            if (editHandler) {
              onTooltipMouseLeave();
              editHandler();
            }
          }
        "
      >
        <template v-if="withIcon">
          <template v-if="customIcon">
            <component :is="customIcon"/>
          </template>
          <template v-else>
            <QuestionIcon/>
          </template>
        </template>
        <template v-else>
          {{ tooltipData }}
        </template>
        <div
            v-if="withDots && !withIcon"
            :style="{ backgroundColor: tooltipBackground }"
            class="tooltip__dots"
        >
          ...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionIcon from "@/assets/img/question.svg";
import {mapMutations} from "vuex";

export default {
  name: "TooltipBody",
  components: {
    QuestionIcon,
  },
  props: {
    tooltipData: {
      type: String,
      default() {
        return "";
      },
    },
    classNames: {
      type: Object,
    },
    tooltipBackground: {
      type: String,
      default() {
        return "#FFFFFF";
      },
    },
    withIcon: {
      type: Boolean,
      default() {
        return false;
      },
    },
    editHandler: {
      type: Function,
    },
    customIcon: {
      type: Object,
    },
    sizeUpdateTime: {
      type: Number,
    },
  },
  data() {
    return {
      withDots: false,
      isVisible: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.tooltipInit();
    }, 100)
  },
  methods: {
    ...mapMutations({
      setTooltipInfo: "expensesStore/setTooltipInfo",
    }),
    tooltipInit() {
      const tooltipVisibleDataRef = this.$refs.tooltipVisibleDataRef;
      if (
          !this.withIcon &&
          tooltipVisibleDataRef &&
          (tooltipVisibleDataRef.scrollWidth >
              tooltipVisibleDataRef.offsetWidth ||
              tooltipVisibleDataRef.scrollHeight >=
              2 * tooltipVisibleDataRef.offsetHeight)
      ) {
        this.withDots = true;
      } else {
        this.withDots = false;
      }
    },
    onTooltipMouseOver() {
      if (!this.isVisible) {
        this.isVisible = true;
        this.setTooltipInfo({
          data: this.tooltipData,
          sourceElement: this.$refs.tooltipVisibleDataRef,
        });
      }
    },
    onTooltipMouseLeave() {
      if (this.isVisible) {
        this.isVisible = false;
        this.setTooltipInfo({
          data: "",
          sourceElement: null,
        });
      }
    },
  },
  watch: {
    tooltipData() {
      this.$nextTick(() => {
        this.tooltipInit();
      });
    },
    sizeUpdateTime() {
      this.$nextTick(() => {
        this.tooltipInit();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  height: 100%;
  text-align: left;
}

.tooltip__visible-data {
  min-height: 17px;
  height: 100%;
  user-select: none;
  overflow: hidden;
}

.tooltip__visible-inner {
  height: 100%;
  position: relative;
  white-space: pre;
}

.tooltip__dots {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  background: #fff;
}
</style>
